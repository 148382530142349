import * as Actions from '../actions';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    documentId: null,
    loadingPhase: '',
    loadProgress: 0,
    clauses: [],
    selectedClause: null,
    alternativesOpen: false,
    updatingAlternative: null,
    updatingClause: null,
    setupVariableContainerId: null,
    setupSignatureContainerId: null,
    selectedPhrase: null,
    selectedTab: 0,
    singleClauseId: null,
    sync_monday: false,
    document_category_id: null,
    signature_type: null,
    is_checklist: false,
    final_format: 'docx',
    on_finish_target_origin: '',
    auto_delete_questionnaires_time: null,
    copiedDocument:null,
    error: null,
    promptAi: '',
    promptAiResult: '',
    promptAiResultUpload: '',
    promptAiResultUploadFilename: '',
    promptAiResultUploadArray: [],
    promptAiResultPatentClaimsArray: [],
    contextData: '',
    promptAiAction: '',
    promptAiError: false,
    promptAiErrorText: '',
    documentTypes: [],
    documentTypesTags: [],
    savedDocumentTypesTagsId:{},
    savedDocumentTypesId:'',
    clauseCategories: [],
    clauseSearch: '',
    clausesPortfolio: [],
    documentNameForKeepClause: '',
    documentXmlForKeepClause: '',
};

const loadDocument = (state, action) => {
    state.documentId = null;
    state.clauses = [];
    state.loadingPhase = 'loading';
    state.name = 'Untitled';
    state.loadProgress = 0;
    state.sync_monday = false;
    state.document_category_id = null;
    state.signature_type = null;
    state.is_checklist = false;
    state.final_format = 'docx';
    state.on_finish_target_origin = '';
    state.auto_delete_questionnaires_time = null;
};

const getDocx = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
    state.error = action.error;
}

const loadProgress = (state, action) => {
    state.loadProgress = action.loadProgress || 0;
    state.loadingPhase = action.loadProgress == 100 ? 'fetching' : 'loading';
};

const gotDocument = (state, action) => {
    if (typeof action.success === 'boolean') {
        if (action.singleClauseId) {
            state.singleClauseId = action.singleClauseId;
        }
        if (!action.skipped) {
            state.name = action.success ? action.document.name : '';
            state.documentId = action.success ? action.document.id : null;
            if (action.success) {
                const clauses = action.document.clauses;
                for (let i = 0; i < clauses.length; ++i) {
                    const c = clauses[i];
                    if (!c.hash) {
                        clauses[i] = state.clauses.find(_c => _c.id === c.id) || c;
                    }
                }    
                state.clauses = clauses;
                if (typeof action.document.sync_monday != 'undefined') {
                    state.sync_monday = action?.document?.sync_monday || false;
                }
                if (typeof action.document.document_category_id != 'undefined') {
                    state.document_category_id = action?.document?.document_category_id || null;
                }
                if (typeof action.document.signature_type != 'undefined') {
                    state.signature_type = action?.document?.signature_type || null;
                }
                if (typeof action.document.is_checklist != 'undefined') {
                    state.is_checklist = action?.document?.is_checklist || false;
                }

                if (typeof action.document.final_format != 'undefined') {
                    state.final_format = action?.document?.final_format || 'docx';
                }

                if (typeof action.document.on_finish_target_origin != 'undefined') {
                    state.on_finish_target_origin = action?.document?.on_finish_target_origin || '';
                }
                if (typeof action.document.auto_delete_questionnaires_time != 'undefined') {
                    state.auto_delete_questionnaires_time = (action?.document?.auto_delete_questionnaires_time || action?.document?.auto_delete_questionnaires_time===0)
                        ? action?.document?.auto_delete_questionnaires_time: null;
                }
            } else if (action.success === false) {
              state.error = action.error;
            }
            state.alternativesOpen = false;
            state.questions = action.document.questions;
            // auto select if there is only one clause. this is good for alternatives view
            if (state.clauses && state.clauses.length === 1) {
                state.selectedClause = state.clauses[0];
            } else if (state.selectedClause) {
                state.selectedClause = state.clauses.find(c => c.id === state.selectedClause.id);
            }
        }
        state.loadingPhase = '';
    } else {
        state.loadingPhase = action.type === Actions.SAVE_DOCUMENT ? 'saving' : 'fetching';
    }
};

const updateDocument = (state, action) => {
    if (action.data){
        for(const key in action.data) {
            state[key] = action.data[key]
        }
    }

    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
}

const selectClause = (state, action) => {
    state.selectedClause = action.clause;
};

const openClause = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
};

const updateClause = (state, action) => {
    if (action.success === true) {
        state.updatingClause = null;
        for(let i = 0; i < state.clauses.length; ++i) {
            if (state.clauses[i].id === action.clause.id) {
                state.clauses[i] = action.clause;
            }
        }
        state.selectedClause = action.clause;
    } else if (action.success === false) {
        state.updatingClause = null;
    } else {
        state.updatingClause = action.clauseId;
    }
};

const deleteAlternative = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.updatingAlternative = null;
    } else {
        state.updatingAlternative = action.alternativeId;
    }
};

const setupVariable = (state, action) => {
    state.setupVariableContainerId = action.containerId;
}

const setupSignature = (state, action) => {
    state.setupSignatureContainerId = action.containerId;
}

const setupPhrase = (state, action) => {
    if (action.success) {
        state.selectedPhrase = action.phrase;
    }
}

const updatePhrase = (state, action) => {
    if (action.success) {
        const phrase = action.phrase;
        for (let i = 0; i < state.clauses.length; ++i) {
            const clause = state.clauses[i];
            if (clause.id === action.phrase.clause_id) {
                for (let j = 0; j < clause.alternatives.length; ++j) {
                    const alternative = clause.alternatives[j];
                    if (!alternative.phrases) {
                        continue;
                    }
                    for (let k = 0; k < alternative.phrases.length; ++k) {
                        if (alternative.phrases[k].id === phrase.id) {
                            alternative.phrases[k] = phrase;
                            break;
                        }
                    }
                }
                state.clauses[i] = clause;
                break;
            }
        }
    }
}

const deletePhrase = (state, action) => {
    if (action.success) {
        for (let i = 0; i < state.clauses.length; ++i) {
            const clause = state.clauses[i];
            if (clause.id === action.clauseId) {
                for (let j = 0; j < clause.alternatives.length; ++j) {
                    const a = clause.alternatives[j];
                    if (!a.phrases) {
                        continue;
                    }
                    for (let k = 0; k < a.phrases.length; ++k) {
                        const phrase = a.phrases[k];
                        if (phrase.id === action.phraseId) {
                            a.phrases =  [...a.phrases.slice(0, k), ...a.phrases.slice(k + 1)]
                            return;
                        }
                    }
                }
            }
        }
    }
}

const setSelectedTab = (state, action) => {
    state.selectedTab = action.tab;
}

const copyDocument = (state, action) => {
    if (action.success) {
        state.copiedDocument = action.document;
    }
}

const clearAllAiPrompt = (state, action) => {
        state.promptAiError = false;
        state.promptAiErrorText = '';
        state.promptAiResult = '';
        state.promptAiAction = '';
        state.promptAi = '';
        state.contextData = '';
        state.promptAiResultUpload = '';
        state.promptAiForPatent = '';
        state.promptDocumentName = '';
        state.promptDocumentDescription = '';
        state.promptAiResultUploadArray = [];
        state.promptAiResultPatentClaimsArray = [];
        state.promptAiResultUploadFilename = '';
}

const askAi = (state, action) => {
    if (action.success) {

        state.promptAiError = false;
        state.promptAiErrorText = '';
        state.promptAiResult = action.result?.result.content.length ? action.result?.result.content[0].text : 'no result from AI try later';
        state.promptAiAction = action.result.action;
        state.promptAi = action.result.prompt;
        state.contextData = action.result?.result.context_data;
    } else {
        state.promptAiError = true;
        state.promptAiErrorText = action.error;
    }
}


const findPatentsWithAIClaims= (state, action) => {
    if (action.success) {
        if( action.result.resultFullPatent.claims.length ) {
            state.promptAiError = false;
            state.promptAiErrorText = '';
            state.promptAiResult = 'Your document has been found successfully';
            state.contextData = '';
            state.promptAiResultUpload = '';
            state.promptAiResultPatentClaimsArray = [
                ...state.promptAiResultPatentClaimsArray,
                {
                    text: `This is Claims: ${action.result.resultFullPatent.claims.join(' ')}. This is Full Text: ${action.result.resultFullPatent.text}`,
                    filename: action.result.patentId
                }
            ];


        } else {
            state.promptAiError = false;
            state.promptAiErrorText = '';
            state.promptAiResult = 'We can`t to find the patent, you can upload patent ';
            state.promptDocumentName = ''
            state.promptDocumentDescription = ''
            state.promptAiResultUpload = '';
        }

    } else {
        state.promptAiError = true;
        state.promptAiErrorText = action.error;
    }
}

const findPatentsWithAI = (state, action) => {
    if (action.success) {
        if( action.result.organic_results.length ) {
            state.promptAiError = false;
            state.promptAiErrorText = '';
            state.promptAiResult = 'Your document has been found successfully';
            state.contextData = '';
            state.promptAiResultUpload = '';
            state.promptDocumentDescription = action.result.organic_results[0].snippet;
            state.promptDocumentName = action.result.organic_results[0].publication_number;
        } else {
            state.promptAiError = false;
            state.promptAiErrorText = '';
            state.promptAiResult = 'We can`t to find the patent, you can upload patent ';
            state.promptDocumentName = ''
            state.promptDocumentDescription = ''
            state.promptAiResultUpload = '';
        }
    } else {
        state.promptAiError = true;
        state.promptAiErrorText = action.error;
    }
}

const askAiMain = (state, action) => {
    state.promptAi = action.data.prompt;
}

const askAiMainForPatent = (state, action) => {
    state.promptAiForPatent = action.data.prompt;
}

const uploadPatent = (state, action) => {
    console.log(' action.result',  action.result)
    if (action.success) {
            state.promptAiError = false;
            state.promptAiErrorText = '';
            state.promptAiResultUpload = action.result.text;
            state.promptAiResultUploadFilename = action.result.filename;
            state.promptAiResultUploadArray = [
                ...state.promptAiResultUploadArray,
                action.result
            ];
            state.promptAiResult = 'You can now ask anything about this document in the query box above';
    } else {
        state.promptAiError = true;
        state.promptAiErrorText = action.error;
    }
}


const getDocumentType = (state, action) => {
    if (action.success) {
        let documentTypes = [];
        let documentTypesTags = [];
        for(let i = 0; i < action.documentType.document_types.length; i++) {
            if (action.documentType.document_types[i].is_tag === true) {
                documentTypesTags.push(action.documentType.document_types[i]);
            } else {
                documentTypes.push(action.documentType.document_types[i]);
            }

            documentTypes.sort((a,b) => (a.name.name > b.name.name) ? 1 : ((b.name.name > a.name.name) ? -1 : 0))
            documentTypesTags.sort((a,b) => (a.name.name > b.name.name) ? 1 : ((b.name.name > a.name.name) ? -1 : 0))

            state.documentTypes = documentTypes
            state.documentTypesTags = documentTypesTags
        }
    } else {
        state.documentTypes = []
        state.documentTypesTags = []
    }
}

const saveDocumentType = (state, action) => {
    if (action.success) {
        if (action.documentType.is_tag === true) {
            state.documentTypesTags.push(action.documentType);
            state.savedDocumentTypesTagsId={
                id: action.documentType.id,
                label: action.documentType.name.name
            };
        } else {
            state.documentTypes.push(action.documentType);
            state.savedDocumentTypesId=action.documentType.id;
        }
    }
}


const getClauseCategories = (state, action) => {
    if (action.success) {
        state.clauseCategories = action.clauseCategories.clause_categories.sort((a,b) => (a.name.name > b.name.name) ? 1 : ((b.name.name > a.name.name) ? -1 : 0));
    } else {
        state.clauseCategories = []
    }
}

const clauseSearch = (state, action) => {
    state.clauseSearch = action.search;
}

const getClauses = (state, action) => {
    if (action.success) {
        state.clausesPortfolio = action.clauses.clauses;
    } else {
        state.clausesPortfolio = [];
    }
}

const getDocumentWithoutDocumentId = (state, action) => {
    if (action.success) {
        state.documentNameForKeepClause = action.name;
        state.documentXmlForKeepClause = action.xml;
    }
}

const resetReducer = (state, action) => {
        state.name= ''
        state.documentId= null
        state.loadingPhase= ''
        state.loadProgress= 0
        state.clauses= []
        state.selectedClause= null
        state.alternativesOpen= false
        state.updatingAlternative= null
        state.updatingClause= null
        state.setupVariableContainerId= null
        state.setupSignatureContainerId= null
        state.selectedPhrase= null
        state.selectedTab= 0
        state.singleClauseId= null
        state.sync_monday= false
        state.document_category_id= null
        state.signature_type= null
        state.is_checklist= false
        state.final_format= 'docx'
        state.on_finish_target_origin= ''
        state.auto_delete_questionnaires_time= null
        state.copiedDocument=null
        state.error= null
        state.promptAi= ''
        state.promptAiResult= ''
        state.promptAiAction= ''
        state.promptAiError= false
        state.promptAiErrorText= ''
        state.ocumentTypes= []
        state.documentTypesTags= []
        state.savedDocumentTypesTagsId={}
        state.savedDocumentTypesId=''
        state.clauseCategories= []
        state.clauseSearch= ''
        state.clausesPortfolio= []
        state.documentNameForKeepClause= ''
        state.documentXmlForKeepClause= ''
}

const resetTags = (state, action) => {
    state.savedDocumentTypesTagsId={}
    state.savedDocumentTypesId=''
    state.clauseCategories= []
}

const resetСlauseSearch= (state, action) => {
    state.clauseSearch=''
}



const handlers = {};
handlers[Actions.LOAD_DOCUMENT] = loadDocument;
handlers[Actions.FETCH_DOCX] = getDocx;
handlers[Actions.LOAD_PROGRESS] = loadProgress;
handlers[Actions.SAVE_DOCUMENT] = gotDocument;
handlers[Actions.FETCH_DOCUMENT] = gotDocument;
handlers[Actions.SELECT_CLAUSE] = selectClause;
handlers[Actions.OPEN_CLAUSE] = openClause;
handlers[Actions.UPDATE_CLAUSE] = updateClause;
handlers[Actions.DELETE_ALTERNATIVE] = deleteAlternative;
handlers[Actions.SETUP_VARIABLE] = setupVariable;
handlers[Actions.SETUP_SIGNATURE] = setupSignature;
handlers[Actions.SETUP_PHRASE] = setupPhrase;
handlers[Actions.UPDATE_PHRASE] = updatePhrase;
handlers[Actions.DELETE_PHRASE] = deletePhrase;
handlers[Actions.SET_SELECTED_TAB] = setSelectedTab;
handlers[Actions.UPDATE_DOCUMENT] = updateDocument;
handlers[Actions.COPY_DOCUMENT] = copyDocument;
handlers[Actions.ASK_AI] = askAi;
handlers[Actions.FIND_PATENT_WITH_AI] = findPatentsWithAI;
handlers[Actions.FIND_PATENT_WITH_AI_CLAIMS] = findPatentsWithAIClaims;
handlers[Actions.ASK_AI_MAIN] = askAiMain;
handlers[Actions.ASK_AI_MAIN_FOR_PATENT] = askAiMainForPatent;
handlers[Actions.UPLOAD_PATENT] = uploadPatent;
handlers[Actions.DOCUMENT_TYPE] = getDocumentType;
handlers[Actions.SAVE_DOCUMENT_TYPE] = saveDocumentType;
handlers[Actions.CLAUSE_CATEGORIES] = getClauseCategories;
handlers[Actions.CLAUSES_PORTFOLIO_SEARCH] = clauseSearch;
handlers[Actions.GET_CLAUSES] = getClauses;
handlers[Actions.GET_DOCUMENT_WITHOUT_DOCUMENT_ID] = getDocumentWithoutDocumentId;
handlers[Actions.RESET_REDUCER] = resetReducer;
handlers[Actions.RESET_TAGS] = resetTags;
handlers[Actions.RESET_CLAUSE_SEARCH] = resetСlauseSearch;
handlers[Actions.CLEAR_ALL_AI_PROMPT] = clearAllAiPrompt;

export default createReducer(initialState, handlers);